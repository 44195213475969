import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Textarea, Tooltip, makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";
import { IChatMessage, ReviseMessageModel } from "../../../libs/models/ChatMessage";
import { useDialogClasses } from "../../../styles";
import { useChat } from "../../../libs/hooks";
import { useState } from "react";
import { Checkmark20 } from "../../shared/BundledIcons";
import { useAppDispatch } from "../../../redux/app/hooks";
import { setSelectedConversation } from "../../../redux/features/conversations/conversationsSlice";

interface IReviseDialogProps {
    userMessage: IChatMessage;
    botResponse: IChatMessage;
}

const useClasses = makeStyles({
    infoButton: {
        ...shorthands.padding(0),
        ...shorthands.margin(0),
        minWidth: 'auto',
        marginLeft: 'auto',
        fontWeight: 'normal',
    },
    text: {
        width: '100%',
        overflowWrap: 'break-word',
    },
    label: {
        fontWeight: '600',
        marginBottom: '0.5em',
    },
    input: {
        marginBottom: '1em',
        marginTop: '2px',
    },
    textField: {
        minHeight: '150px',
    },
    action: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '1em',
        marginBottom: '1em',
        marginRight: '5px',
    },
    updateButton: {
        backgroundColor: '#0078d4',
        color: 'white',
    },
    deleteButton: {
        backgroundColor: '#e81123',
        color: 'white',
    },
    hidden: {
        display: 'none',
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: 'bold',
    },
    warning: {
        right: '10px',
        color: 'green',
        fontWeight: 'bold',
    }
});

export const ReviseDialog: React.FC<IReviseDialogProps> = ({ userMessage, botResponse }) => {
    const classes = useClasses();
    const dialogClasses = useDialogClasses();
    const chat = useChat();
    const dispatch = useAppDispatch();
    const userContent = userMessage?.content;
    const botContent = botResponse?.content;
    const [value, setValue] = useState('');
    const [disable, setDisable] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [isDialogOpen, setDialogOpen] = useState(false);

    function getRevisedAnswer() {
        if (botResponse.trainingMemorySourceId !== '' && botResponse.trainingMemorySourceId !== undefined) {
            void chat.getRevisedAnswer(process.env.REACT_APP_CHAT_BOT_ID ?? '', botResponse.trainingMemorySourceId).then((response: ReviseMessageModel) => {
                setValue(response.answer);
            });
        }
    }

    function getChatsAfterRevise() {
        void chat.loadChangedChat(userMessage.chatId).then(() => {
            dispatch(setSelectedConversation(userMessage.chatId));
            setDisable(false);

            handleCloseDialog();
        });
    }

    const handleOpenDialog = () => {
        setDialogOpen(true);
    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    return (
        <div>
            <Dialog 
                open={isDialogOpen}
                onOpenChange={(_e, data) => {
                    setSuccessMessage(null);
                    if (data.open) {
                        getRevisedAnswer()
                    }
                }}
            >
                <DialogTrigger disableButtonEnhancement>
                    <Tooltip content={'Change how the bot answers'} relationship="label">
                        <Button className={classes.infoButton} appearance="transparent" onClick={handleOpenDialog}>
                            {botResponse.trainingMemorySourceId !== '' ? 'Revised' : 'Revise answer'}
                            &nbsp;
                            {botResponse.trainingMemorySourceId !== '' ? <Checkmark20 /> : null}
                        </Button>
                    </Tooltip>
                </DialogTrigger>
                <DialogSurface className={dialogClasses.surface}>
                    <DialogBody
                        style={{
                            height: 'fit-content',
                            paddingRight: '1em',
                        }}
                    >
                        <DialogTitle className={classes.title}>
                            Revise answer
                            {successMessage && (
                                <div className={classes.warning}>
                                    {successMessage}
                                </div>
                            )}
                        </DialogTitle>
                    
                        <DialogContent className={dialogClasses.content}>
                            <label className={classes.label}>User message</label>
                            <Textarea
                                value={userContent}
                                className={classes.input}
                            />
                            <label className={classes.label}>Bot response</label>
                            <Textarea
                                value={botContent}
                                className={mergeClasses(classes.input, classes.textField)}
                                resize="vertical"
                            />
                            <label className={classes.label}>Expected response</label>
                            <Textarea
                                resize="vertical"
                                className={classes.input}
                                value={value}
                                id="expected-response"
                                onFocus={() => {
                                    const textInput = document.getElementById('expected-response');
                                    if (textInput) {
                                        setValue((textInput as HTMLTextAreaElement).value);
                                    }
                                }}
                                onChange={(_event, data) => {
                                    setValue(data.value);
                                }}
                            />
                        </DialogContent>
                        <DialogActions position="end" className={classes.action}>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary" onClick={handleCloseDialog}>
                                    Close
                                </Button>
                            </DialogTrigger>
                            <Button className={classes.updateButton}
                                disabled={disable}
                                onClick={() => {
                                    if(botResponse.trainingMemorySourceId !== '' && botResponse.trainingMemorySourceId !== undefined){
                                        if(value !== ''){
                                            setDisable(true);
                                            void chat.updateRevisedAnswer(process.env.REACT_APP_CHAT_BOT_ID ?? '', botResponse.trainingMemorySourceId, value).then(() => {
                                                getChatsAfterRevise();
                                            }).catch(() => {
                                                setSuccessMessage('Failed!');
                                                setDisable(false);
                                            });
                                        }
                                    }else{
                                        if(value !== ''){
                                            const body: ReviseMessageModel = {
                                                question: userContent,
                                                answer: value,
                                                chatMessageId: botResponse.id ?? '',
                                            }
                                            setDisable(true);
                                            void chat.reviseAnswer(process.env.REACT_APP_CHAT_BOT_ID ?? '', userMessage.chatId, body).then(() => {
                                                getChatsAfterRevise();
                                            }).catch(() => {
                                                setSuccessMessage('Failed!');
                                                setDisable(false);
                                            });
                                        }
                                    }
                                }}>
                                Update
                            </Button>
                            <Button className={botResponse.trainingMemorySourceId !== '' ? classes.deleteButton : classes.hidden}
                                disabled={disable}
                                onClick={() => {
                                    if(botResponse.trainingMemorySourceId !== '' && botResponse.trainingMemorySourceId !== undefined && !disable){
                                        setDisable(true);
                                        void chat.deleteRevisedAnswer(process.env.REACT_APP_CHAT_BOT_ID ?? '', userMessage.chatId, botResponse.trainingMemorySourceId).then(() => {
                                            getChatsAfterRevise();
                                            setValue('');
                                        }).catch(() => {
                                            setSuccessMessage('Failed!');
                                            setDisable(false);
                                        });
                                    }
                                }}>
                                Delete
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </div>
    );
}
import { BrowserRouter, Routes as Router, Route } from 'react-router-dom';
import ChatIFrame from './pages/ChatIFrame';
import App from './App';
import NotFound from './pages/NotFound';

export const Routes = () => {
    return (
        <BrowserRouter>
            <Router>
                <Route path="/management/:checkId" element={<App />} />
                <Route path="/chat-iframe/:chatId" element={<ChatIFrame />} />
                <Route path="*" element={<NotFound />} />
            </Router>
        </BrowserRouter>
    );
};

import { MaintenanceService } from "../services/MaintenanceService";

export const useManagement = () => {
    const maintains = new MaintenanceService();

    const checkManagementAccess = async (id: string): Promise<boolean> => {
        return await maintains.checkManagementAccess(id);
    };

    return {
        checkManagementAccess
    };
}
import { FeatureKeys } from '../redux/features/app/AppState';
import { useChat } from '../libs/hooks/useChat';
import { useAppDispatch, useAppSelector } from '../redux/app/hooks';
import React, { useEffect } from 'react';
import { RootState } from '../redux/app/store';
import { FluentProvider, Subtitle1, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { semanticKernelDarkTheme, semanticKernelLightTheme } from '../styles';
import { AppState } from '../services/models/Enum';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { addAlert, setActiveUserInfo, setChatBotConfig } from '../redux/features/app/appSlice';
import { AlertType } from '../libs/models/AlertType';
import { AuthHelper } from '../libs/auth/AuthHelper';
import { Loading, Login, Error, BackendProbe } from '../components/views';
import { ChatContent } from '../components/chat/chat-frame/ChatContent';
import { useParams } from 'react-router-dom';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { IChatBotConfig } from '../libs/models/ChatBotConfig';

const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        ...shorthands.overflow('hidden'),
    },
    header: {
        alignItems: 'center',
        backgroundColor: tokens.colorBrandForeground2,
        color: tokens.colorNeutralForegroundOnBrand,
        display: 'flex',
        '& h1': {
            paddingLeft: tokens.spacingHorizontalXL,
            display: 'flex',
        },
        height: '48px',
        justifyContent: 'space-between',
        width: '100%',
    },
});

const ChatIFrame = () => {
    const classes = useClasses();
    const [appState, setAppState] = React.useState(AppState.ProbeForBackend);
    const dispatch = useAppDispatch();

    const { instance } = useMsal();
    const { features, isMaintenance } = useAppSelector((state: RootState) => state.app);
    const isAuthenticated = useIsAuthenticated();

    const chat = useChat();

    const { chatId } = useParams<{ chatId: string }>();

    const [isVerified, setIsVerified] = React.useState(false);

    const reCaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_APIKEY ?? '';

    useEffect(() => {
        if (isMaintenance && appState !== AppState.ProbeForBackend) {
            setAppState(AppState.ProbeForBackend);
            return;
        }

        if (isAuthenticated && appState === AppState.SettingUserInfo) {
            const account = instance.getActiveAccount();
            if (!account) {
                setAppState(AppState.ErrorLoadingUserInfo);
            } else {
                dispatch(
                    setActiveUserInfo({
                        id: `${account.localAccountId}.${account.tenantId}`,
                        email: account.username,
                        username: account.name ?? account.username,
                    }),
                );

                if (account.username.split('@')[1] === 'microsoft.com') {
                    dispatch(
                        addAlert({
                            message:
                                'By using VLink Chatbot, you agree to protect sensitive data, not store it in chat, and allow chat history collection for service improvements. This tool is for internal use only.',
                            type: AlertType.Info,
                        }),
                    );
                }
                setAppState(AppState.LoadingChats);
            }
        }

        if ((isAuthenticated || !AuthHelper.isAuthAAD()) && appState === AppState.LoadingChats) {
            void Promise.all([
                chatId &&
                    chat
                        .getChatBotConfigByChatId(chatId)
                        .then((result: IChatBotConfig) => {
                            dispatch(setChatBotConfig(result));
                        })
                        .catch(() => setAppState(AppState.ErrorLoadingChats)),
                chatId &&
                    chat
                        .loadChat(chatId)
                        .then(() => {
                            setAppState(AppState.Chat);
                        })
                        .catch(() => {
                            setAppState(AppState.ErrorLoadingChats);
                        }),
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance, isAuthenticated, appState, isMaintenance, dispatch, reCaptchaKey]);

    const content = <Chat classes={classes} appState={appState} setAppState={setAppState} />;
    return (
        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey} language="en">
            {!isVerified && (
                <GoogleReCaptcha
                    onVerify={(token) => {
                        if (token) {
                            setIsVerified(true);
                        } else {
                            setIsVerified(false);
                        }
                    }}
                />
            )}
            <base target="_blank" />
            <FluentProvider
                className="app-container"
                theme={features[FeatureKeys.DarkMode].enabled ? semanticKernelDarkTheme : semanticKernelLightTheme}
            >
                {AuthHelper.isAuthAAD() ? (
                    <>
                        <UnauthenticatedTemplate>
                            <div className={classes.container}>
                                <div className={classes.header}>
                                    <Subtitle1 as="h1">VLink Chatbot</Subtitle1>
                                </div>
                                {appState === AppState.SigningOut && <Loading text="Signing you out..." />}
                                {appState !== AppState.SigningOut && <Login />}
                            </div>
                        </UnauthenticatedTemplate>
                        <AuthenticatedTemplate>{content}</AuthenticatedTemplate>
                    </>
                ) : (
                    <>{isVerified && content}</>
                )}
            </FluentProvider>
        </GoogleReCaptchaProvider>
    );
};

export default ChatIFrame;

const Chat = ({
    classes,
    appState,
    setAppState,
}: {
    classes: ReturnType<typeof useClasses>;
    appState: AppState;
    setAppState: (state: AppState) => void;
}) => {
    const onBackendFound = React.useCallback(() => {
        setAppState(AuthHelper.isAuthAAD() ? AppState.SettingUserInfo : AppState.LoadingChats);
    }, [setAppState]);

    return (
        <div className={classes.container}>
            {appState === AppState.ProbeForBackend && <BackendProbe onBackendFound={onBackendFound} />}
            {appState === AppState.SettingUserInfo && (
                <Loading text={'Hang tight while we fetch your information...'} />
            )}
            {appState === AppState.LoadingChats && <Loading text="Loading chats..." />}
            {appState === AppState.ErrorLoadingChats && (
                <Error text={'Unable to load chats. Please try refreshing the page.'} />
            )}
            {appState === AppState.Chat && <ChatContent />}
        </div>
    );
};

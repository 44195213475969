import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import React from 'react';
import { IChatMessage } from '../../../../libs/models/ChatMessage';
import { HistoryItemFrame } from './HistoryItemFrame';

const useClasses = makeStyles({
    root: {
        ...shorthands.gap(tokens.spacingVerticalM),
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '105em',
        width: '100%',
        justifySelf: 'center',
        ...shorthands.overflow('hidden'),
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
    },
});

interface ChatHistoryProps {
    messages: IChatMessage[];
}

export const HistoryFrame: React.FC<ChatHistoryProps> = ({ messages }) => {
    const classes = useClasses();

    return (
        <div className={classes.root}>
            {messages.map((message, index) => (
                <HistoryItemFrame key={message.createdOn} message={message} messageIndex={index} />
            ))}
        </div>
    );
};

import { Text, makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import { ThumbDislikeFilled, ThumbLikeFilled } from '@fluentui/react-icons';
import React, { useEffect, useRef, useState } from 'react';
import { useChat } from '../../../../libs/hooks/useChat';
import { AuthorRoles, ChatMessageType, IChatMessage, UserFeedback } from '../../../../libs/models/ChatMessage';
import { useAppSelector } from '../../../../redux/app/hooks';
import { RootState } from '../../../../redux/app/store';
import { DefaultChatUser, FeatureKeys } from '../../../../redux/features/app/AppState';
import { Breakpoints, customTokens } from '../../../../styles';
import { timestampToDateString } from '.././../../utils/TextUtils';
import { PlanViewer } from '../../plan-viewer/PlanViewer';
import { TypingIndicator } from '../../typing-indicator/TypingIndicator';
import * as utils from '../../../utils/TextUtils';
import { HistoryDocumentContent } from './HistoryDocumentContent';
import { ChatHistoryTextContent } from './ChatHistoryTextContent';
import { RecommendQuestion } from '../recommend-question/RecommendQuestion';
import { renderToString } from 'react-dom/server';
import FilledIconSVG from '../svg/FilledHintSVG';
import DefaultCornerSVG from '../svg/DefaultCornerSVG';
import FilledCornerSVG from '../svg/FilledCornerSVG';
const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.borderRadius(customTokens.borderRadiusMedium),
        ...Breakpoints.small({
            maxWidth: '100%',
        }),
        position: 'relative',
    },
    debug: {
        position: 'absolute',
        top: '-4px',
        right: '-4px',
    },
    alignEnd: {
        alignSelf: 'flex-end',
    },
    item: {
        backgroundColor: 'white',
        ...shorthands.borderRadius('12px', '12px', '12px', '12px'),
        ...shorthands.padding(customTokens.spacingVerticalXS, customTokens.spacingHorizontalM),
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
        marginTop: '1px',
        marginLeft: '3px',
    },
    me: {
        backgroundColor: '#1359C8',
        ...shorthands.borderRadius('12px', '12px', '12px', '12px'),
        ...shorthands.padding(customTokens.spacingVerticalXS, customTokens.spacingHorizontalM),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'white',
        boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
        marginRight: '3px',
    },
    info: {
        ...shorthands.borderRadius('10px'),
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding(customTokens.spacingVerticalXS, 0),
    },
    infoMe: {
        justifyContent: 'end',
    },
    time: {
        color: customTokens.colorNeutralForeground3,
        fontSize: customTokens.fontSizeBase200,
        fontWeight: 400,
    },
    header: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap(customTokens.spacingHorizontalL),
    },
    canvas: {
        width: '100%',
        textAlign: 'center',
    },
    image: {
        maxWidth: '250px',
    },
    blur: {
        filter: 'blur(5px)',
    },
    controls: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: customTokens.spacingVerticalS,
        marginBottom: customTokens.spacingVerticalS,
        ...shorthands.gap(customTokens.spacingHorizontalL),
    },
    citationButton: {
        marginRight: 'auto',
    },
    rlhf: {
        marginLeft: 'auto',
    },
    frame: {
        maxWidth: '100%',
        ...shorthands.margin(
            customTokens.spacingVerticalS,
            customTokens.spacingVerticalS,
            customTokens.spacingVerticalS,
            0,
        ),
    },
    questionFrame: {
        position: 'relative',
        right: '0',
        maxWidth: '100%',
        ...shorthands.borderRadius(customTokens.borderRadiusMedium),
        marginLeft: '3px',
    },
    cornerLeft: {
        lineHeight: '0',
        position: 'absolute',
    },
    cornerRight: {
        lineHeight: '0',
        position: 'absolute',
        right: '0',
    },
});

interface ChatHistoryItemProps {
    message: IChatMessage;
    messageIndex: number;
}

export const HistoryItemFrame: React.FC<ChatHistoryItemProps> = ({ message, messageIndex }) => {
    const classes = useClasses();
    const chat = useChat();

    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { activeUserInfo, features } = useAppSelector((state: RootState) => state.app);

    const { chatBotConfig } = useAppSelector((state: RootState) => state.app);
    const [recommendedQuestions] = useState(chatBotConfig?.hintQuestions ?? []);

    const isDefaultUser = message.userId === DefaultChatUser.id;
    const isMe = isDefaultUser || (message.authorRole === AuthorRoles.User && message.userId === activeUserInfo?.id);
    const isBot = message.authorRole === AuthorRoles.Bot;
    const user = isDefaultUser
        ? DefaultChatUser
        : chat.getChatUserById(message.userName, selectedId, conversations[selectedId].users);
    const fullName = user?.fullName ?? message.userName;

    let content: JSX.Element;
    if (isBot && message.type === ChatMessageType.Plan) {
        content = <PlanViewer message={message} messageIndex={messageIndex} />;
    } else if (message.type === ChatMessageType.Document) {
        content = <HistoryDocumentContent isMe={isMe} message={message} />;
    } else {
        content =
            isBot && message.content.length === 0 ? <TypingIndicator /> : <ChatHistoryTextContent message={message} />;
    }

    const messageRef = useRef<HTMLDivElement>(null);
    const [messageWidth, setMessageWidth] = useState<number | undefined>(undefined);

    const [isHintExpanded, setIsHintExpanded] = useState(conversations[selectedId].messages.length > 1 ? false : true);

    const questionFrameRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (messageIndex === 0 && messageRef.current) {
            setMessageWidth(messageRef.current.clientWidth);
        }
    }, [messageRef]);

    useEffect(() => {
        if (questionFrameRef.current) {
            const questionFrameClone = questionFrameRef.current.cloneNode(true) as HTMLDivElement;

            if (conversations[selectedId].messages.length > 1 && questionFrameClone) {
                setIsHintExpanded(false);
            }
            if (conversations[selectedId].messages.length === 2) {
                questionFrameRef.current.style.opacity = '0';
                document.body.appendChild(questionFrameClone);

                questionFrameClone.style.position = 'absolute';
                questionFrameClone.style.zIndex = '100';
                questionFrameClone.style.left = questionFrameRef.current?.offsetLeft + 'px';
                questionFrameClone.style.top = questionFrameRef.current?.offsetTop + 'px';

                questionFrameClone.animate(
                    [
                        {
                            transform: 'scale(0)',
                            transformOrigin: 'top left',
                            opacity: 0,
                        },
                    ],
                    {
                        duration: 300,
                        easing: 'ease-in-out',
                    },
                ).onfinish = () => {
                    const svgFilledClone = document.createElement('div');
                    svgFilledClone.innerHTML = renderToString(<FilledIconSVG />);
                    questionFrameClone.style.transform = 'scale(0)';
                    questionFrameClone.style.transformOrigin = 'top left';
                    questionFrameClone.style.opacity = '0';

                    svgFilledClone.style.position = 'absolute';
                    svgFilledClone.style.zIndex = '101';
                    svgFilledClone.style.left = questionFrameClone.offsetLeft + 'px';
                    svgFilledClone.style.top = questionFrameClone.offsetTop + 'px';

                    document.body.appendChild(svgFilledClone);
                    svgFilledClone.animate(
                        [
                            {
                                left: -((questionFrameClone.clientLeft - 15) / 2) + 'px',
                                top: window.innerHeight - questionFrameClone.offsetTop + 20 + 'px',
                            },
                        ],
                        {
                            duration: 700,
                            easing: 'ease-in-out',
                        },
                    ).onfinish = () => {
                        svgFilledClone.style.left = -((questionFrameClone.clientLeft - 15) / 2) + 'px';
                        svgFilledClone.style.top = window.innerHeight - questionFrameClone.offsetTop + 20 + 'px';
                        document.body.removeChild(questionFrameClone);
                        document.body.removeChild(svgFilledClone);
                    };
                };
            }
        }
    }, [conversations[selectedId].messages.length]);

    return (
        <div className={isMe ? classes.alignEnd : classes.frame}>
            <div
                className={classes.root}
                data-testid={`chat-history-item-${messageIndex}`}
                data-username={fullName}
                data-content={utils.formatChatTextContent(message.content)}
            >
                <div>
                    <div className={classes.info} ref={messageRef}>
                        <div className={isMe ? mergeClasses(classes.header, classes.infoMe) : classes.header}>
                            {!isMe && <Text weight="semibold">{fullName}</Text>}
                            <Text className={classes.time}>{timestampToDateString(message.createdOn, true)}</Text>
                        </div>
                    </div>
                    <div className={isMe ? classes.cornerRight : classes.cornerLeft}>
                        {isMe ? <FilledCornerSVG /> : <DefaultCornerSVG />}
                    </div>

                    <div
                        className={isMe ? classes.me : classes.item}
                        style={{ maxWidth: (window.innerWidth - 24) * 0.8 }}
                    >
                        {content}
                    </div>
                </div>
                {features[FeatureKeys.RLHF].enabled && message.userFeedback === UserFeedback.Positive && (
                    <ThumbLikeFilled color="gray" />
                )}
                {features[FeatureKeys.RLHF].enabled && message.userFeedback === UserFeedback.Negative && (
                    <ThumbDislikeFilled color="gray" />
                )}
            </div>
            {recommendedQuestions.length > 0 && messageIndex === 0 && isHintExpanded && (
                <div ref={questionFrameRef} className={classes.questionFrame} style={{ width: messageWidth }}>
                    <RecommendQuestion />
                </div>
            )}
        </div>
    );
};

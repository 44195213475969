import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { FC } from 'react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { ChatWindow } from '../chat/ChatWindow';
import { ChatList } from '../chat/chat-list/ChatList';

const useClasses = makeStyles({
    container: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
    },
    warning: {
        width: '100%',
        textAlign: 'center',
        alignSelf: 'center',
        fontWeight: tokens.fontWeightMedium,
    },
});

export const ChatView: FC = () => {
    const classes = useClasses();
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);

    return (
        <div className={classes.container}>
            <ChatList />
            {selectedId !== '' ? <ChatWindow /> : <h2 className={classes.warning}>Create new or select an existing chat!</h2>}
        </div>
    );
};

import { Button, Textarea, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import { RecordStop24Regular, Send20Filled } from '@fluentui/react-icons';
import debug from 'debug';
import React, { useRef, useState } from 'react';
import { Constants } from '../../../Constants';
import { COPY } from '../../../assets/strings';
import { GetResponseOptions } from '../../../libs/hooks/useChat';
import { AlertType } from '../../../libs/models/AlertType';
import { AuthorRoles, ChatMessageType } from '../../../libs/models/ChatMessage';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { addAlert } from '../../../redux/features/app/appSlice';
import {
    editConversationInput,
    updateBotResponseStatus,
} from '../../../redux/features/conversations/conversationsSlice';
import { Alerts } from '../../shared/Alerts';
import { StatusChat } from './StatusChat';
import { RecommendQuestion } from './recommend-question/RecommendQuestion';
import { customTokens } from '../../../styles';
import FilledIconSVG from './svg/FilledHintSVG';
import DefaultIconSVG from './svg/DefaultHintSVG';

const log = debug(Constants.debug.root).extend('chat-input');

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '105em',
    },
    typingIndicator: {
        maxHeight: '28px',
        paddingLeft: '10px',
    },
    content: {
        ...shorthands.gap(tokens.spacingHorizontalM),
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        backgroundColor: customTokens.colorNeutralBackground1,
    },
    inputContainer: {
        width: '100%',
        position: 'relative',
    },
    input: {
        width: '100%',
    },
    textarea: {
        height: '30px',
        maxHeight: '80px',
        marginRight: '15px',
        lineHeight: '15px',
        paddingTop: '17px',
    },
    textareaMarginLeft: {
        marginLeft: '25px',
    },
    controls: {
        display: 'flex',
        flexDirection: 'row',
    },
    essentials: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    functional: {
        display: 'flex',
        flexDirection: 'row',
    },
    dragAndDrop: {
        ...shorthands.border(tokens.strokeWidthThick, ' solid', tokens.colorBrandStroke1),
        ...shorthands.padding('8px'),
        textAlign: 'center',
        backgroundColor: tokens.colorNeutralBackgroundInvertedDisabled,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorBrandForeground1,
        caretColor: 'transparent',
    },
    submitButton: {
        ...shorthands.margin(tokens.spacingVerticalNone, tokens.spacingHorizontalNone),
        position: 'absolute',
        top: '10px',
        right: '5px',
        color: 'black',
    },
    hintButton: {
        ...shorthands.margin(tokens.spacingVerticalNone, tokens.spacingHorizontalNone),
        position: 'absolute',
        top: '10px',
        left: '5px',
        color: 'black',
    },
    greySubmitButton: {
        ...shorthands.margin(tokens.spacingVerticalNone, tokens.spacingHorizontalNone),
        position: 'absolute',
        top: '10px',
        right: '5px',
        color: tokens.colorNeutralForegroundDisabled,
    },
    hintContainer: {
        ...shorthands.overflow('hidden'),
        ...shorthands.transition('max-height', '0.5s', 'ease-in-out 0.5s'),
        ...shorthands.margin('0', '0', '0', '20px'),
    },
});

interface ChatInputProps {
    isDraggingOver?: boolean;
    onDragLeave: React.DragEventHandler<HTMLDivElement | HTMLTextAreaElement>;
    onSubmit: (options: GetResponseOptions) => Promise<void>;
}

export const InputChat: React.FC<ChatInputProps> = ({ onSubmit }) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);

    const { chatBotConfig } = useAppSelector((state: RootState) => state.app);
    const [recommendedQuestions] = useState(chatBotConfig?.hintQuestions ?? []);

    const [value, setValue] = useState('');
    const hintContainerRef = useRef<HTMLDivElement>(null);
    // const { importingDocuments } = conversations[selectedId];

    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
    const expandButtonRef = useRef<HTMLButtonElement>(null);

    const [isHintExpanded, setIsHintExpanded] = useState(false);
    const [isMinimized, setIsMinimized] = useState(false);
    const [effectTrigger, setEffectTrigger] = useState(false);
    React.useEffect(() => {
        // Focus on the text area when the selected conversation changes
        textAreaRef.current?.focus();
    }, [selectedId]);

    React.useEffect(() => {
        const chatState = conversations[selectedId];
        setValue(chatState.disabled ? COPY.CHAT_DELETED_MESSAGE() : chatState.input);
        const newestMessage = conversations[selectedId].messages[conversations[selectedId].messages.length - 1];

        if (conversations[selectedId].messages.length == 2) {
            if (!effectTrigger) {
                setIsHintExpanded(false);
                setEffectTrigger(true);
                setTimeout(() => {
                    setIsMinimized(true);
                    expandButtonRef.current?.animate(
                        [{ transform: 'scale(1)' }, { transform: 'scale(0.5)' }, { transform: 'scale(1)' }],
                        {
                            duration: 500,
                            iterations: 1,
                        },
                    );
                    setIsMinimized(false);
                }, 1000);
            }
        } else if (newestMessage?.authorRole === AuthorRoles.User) {
            setIsHintExpanded(false);
        }
    }, [conversations, selectedId]);

    React.useEffect(() => {
        const hintContainer = hintContainerRef.current;
        if (!hintContainer) {
            return;
        }
        if (isHintExpanded && hintContainer) {
            hintContainer.style.maxHeight = `${hintContainer.scrollHeight}px`;
            hintContainer.style.marginBottom = '20px';
        } else {
            hintContainer.style.maxHeight = '0';
            hintContainer.style.marginBottom = '0';
        }
    }, [isHintExpanded]);

    const handleSubmit = (value: string, messageType: ChatMessageType = ChatMessageType.Message) => {
        if (value.trim() === '') {
            return; // only submit if value is not empty
        }

        setValue('');
        dispatch(editConversationInput({ id: selectedId, newInput: '' }));
        dispatch(updateBotResponseStatus({ chatId: selectedId, status: 'Calling the kernel' }));
        onSubmit({ value, messageType, chatId: selectedId }).catch((error) => {
            const message = `Error submitting chat input: ${(error as Error).message}`;
            log(message);
            dispatch(
                addAlert({
                    type: AlertType.Error,
                    message,
                }),
            );
        });
    };

    let message = conversations[selectedId].botResponseStatus;

    return (
        <div className={classes.root}>
            <div className={classes.typingIndicator}>
                <StatusChat />
            </div>
            <Alerts />
            <div className={classes.content}>
                <div className={classes.inputContainer}>
                    <Textarea
                        placeholder="Write a message..."
                        ref={textAreaRef}
                        id="chat-input"
                        disabled={conversations[selectedId].disabled}
                        textarea={{
                            className: mergeClasses(
                                classes.textarea,
                                recommendedQuestions.length > 0 ? classes.textareaMarginLeft : '',
                            ),
                            maxLength: 500,
                        }}
                        className={classes.input}
                        value={value}
                        onFocus={() => {
                            // update the locally stored value to the current value
                            const chatInput = document.getElementById('chat-input');
                            if (chatInput) {
                                setValue((chatInput as HTMLTextAreaElement).value);
                            }
                            setIsHintExpanded(false);
                        }}
                        onChange={(_event, data) => {
                            setValue(data.value);
                            dispatch(editConversationInput({ id: selectedId, newInput: data.value }));
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && !event.shiftKey) {
                                event.preventDefault();
                                if (message === '' || message === undefined || !message) {
                                    handleSubmit(value);
                                }
                            }
                        }}
                    />
                    {recommendedQuestions.length > 0 && (
                        <Button
                            appearance="transparent"
                            ref={expandButtonRef}
                            icon={isHintExpanded || isMinimized ? <FilledIconSVG /> : <DefaultIconSVG />}
                            onClick={() => {
                                setIsHintExpanded(!isHintExpanded);
                            }}
                            className={classes.hintButton}
                        />
                    )}

                    <Button
                        title="Submit"
                        aria-label="Submit message"
                        appearance="transparent"
                        icon={
                            message === '' || message === undefined || !message ? (
                                <Send20Filled />
                            ) : (
                                <RecordStop24Regular />
                            )
                        }
                        onClick={() => {
                            message === '' || message === undefined || !message ? handleSubmit(value) : null;
                        }}
                        disabled={conversations[selectedId].disabled}
                        className={
                            message === '' || message === undefined || !message
                                ? value && value.trim() !== ''
                                    ? classes.submitButton
                                    : classes.greySubmitButton
                                : classes.submitButton
                        }
                    />
                    {recommendedQuestions.length > 0 && (
                        <div className={`${classes.hintContainer}`} ref={hintContainerRef}>
                            <RecommendQuestion />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

import React from 'react';
import notFoundSrc from '../assets/not_found.jpeg';

const NotFound: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <img
        src={notFoundSrc}
        alt="404"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
        }}
      />
    </div>
  );
};

export default NotFound;
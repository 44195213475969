import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { useState } from 'react';
import { customTokens } from '../../../../styles';
import { RootState } from '../../../../redux/app/store';
import { useAppDispatch, useAppSelector } from '../../../../redux/app/hooks';
import {
    editConversationInput,
    updateBotResponseStatus,
} from '../../../../redux/features/conversations/conversationsSlice';
import { useChat } from '../../../../libs/hooks';
import { ChatMessageType } from '../../../../libs/models/ChatMessage';
import React from 'react';

const useClasses = makeStyles({
    frame: {
        maxWidth: '100%',
        alignSelf: 'flex-end',
        justifyContent: 'end',
        display: 'inline-table',
        flexDirection: 'column',
        ...shorthands.gap('4px'),
        ...shorthands.margin(customTokens.spacingVerticalM, '0', '0', '0'),
        ...shorthands.borderRadius(customTokens.borderRadiusMedium),
    },
    questionTab: {
        ...shorthands.borderRadius('5px'),
        ...shorthands.padding(customTokens.spacingVerticalXS, customTokens.spacingHorizontalS),
        backgroundColor: 'white',
        ...shorthands.border('0.5px', 'solid', '#1890FF'),
        cursor: 'pointer',
        color: '#1890FF',
        '&:hover': {
            backgroundColor: tokens.colorPaletteBlueBorderActive,
            color: 'white',
        },
        display: 'inline-block',
        justifyContent: 'center',
        ...shorthands.margin('4px', '8px', '4px', '0'),
    },
    selected: {
        backgroundColor: customTokens.colorNeutralBackground1Pressed,
    },
});

export const RecommendQuestion: React.FC = () => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { chatBotConfig } = useAppSelector((state: RootState) => state.app);
    const chat = useChat();

    let message = conversations[selectedId].botResponseStatus;

    const handleQuestionClick = async (value: string, messageType: ChatMessageType = ChatMessageType.Message) => {
        (message === '' || message === undefined || !message) &&
            (dispatch(editConversationInput({ id: selectedId, newInput: '' })),
            dispatch(updateBotResponseStatus({ chatId: selectedId, status: 'Calling the kernel' })),
            await chat.getResponse({ messageType, value, chatId: selectedId }),
            dispatch(updateBotResponseStatus({ chatId: selectedId, status: '' })));
    };

    const [recommendedQuestions] = useState(chatBotConfig?.hintQuestions ?? []);

    return (
        <span className={classes.frame}>
            {recommendedQuestions.map((question, index) => (
                <span key={index} className={classes.questionTab} onClick={() => handleQuestionClick(question)}>
                    {question}&nbsp;
                </span>
            ))}
        </span>
    );
};

import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Tooltip,
    makeStyles,
    shorthands,
} from '@fluentui/react-components';
import { useCallback, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { useDialogClasses } from '../../../../styles';
import { useChat } from '../../../../libs/hooks';
import { DataFunnel20Regular } from '@fluentui/react-icons';
import { parseDateWithOffset } from '../../../../libs/hooks/useChat';
import { useAppSelector } from '../../../../redux/app/hooks';
import { RootState } from '../../../../redux/app/store';

const useClasses = makeStyles({
    infoButton: {
        ...shorthands.padding(0),
        ...shorthands.margin(0),
        minWidth: 'auto',
        marginLeft: 'auto',
        fontWeight: 'normal',
        marginTop: '0.5em',
    },
    title: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    action: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '1em',
        marginBottom: '1em',
        marginRight: '5px',
    },
    filterButton: {
        backgroundColor: '#0078d4',
        color: 'white',
    },
});

export const DateRangeDialog: React.FC<{ onFilterChange: (startDate: Date, endDate: Date) => void }> = ({
    onFilterChange,
}) => {
    const classes = useClasses();
    const dialogClasses = useDialogClasses();
    const chat = useChat();

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isDialogOpen, setDialogOpen] = useState(false);

    const { listChatBotConfig } = useAppSelector((state: RootState) => state.app);

    const chatBotId = listChatBotConfig?.[0]?.chatBotId;

    const handleSelect = useCallback((ranges: any) => {
        setStartDate(ranges.selection.startDate);
        setEndDate(ranges.selection.endDate);
    }, []);

    const handleOpenDialog = useCallback(() => {
        setDialogOpen(true);
    }, []);

    const handleCloseDialog = useCallback(() => {
        setDialogOpen(false);
    }, []);

    const handleFilter = useCallback(() => {
        const inputStartDate = parseDateWithOffset(startDate.toISOString());
        const inputEndDate = parseDateWithOffset(endDate.toISOString());

        void chat.loadListChats(chatBotId ?? '', inputStartDate, inputEndDate).then(() => {
            onFilterChange(startDate, endDate);
            handleCloseDialog();
        });
    }, [startDate, endDate, chat, onFilterChange, handleCloseDialog]);

    return (
        <div>
            <Dialog aria-labelledby="form-dialog-title" open={isDialogOpen}>
                <DialogTrigger disableButtonEnhancement>
                    <Tooltip content={'Filter chat sessions'} relationship="label">
                        <Button
                            className={classes.infoButton}
                            appearance="transparent"
                            icon={<DataFunnel20Regular />}
                            onClick={handleOpenDialog}
                        />
                    </Tooltip>
                </DialogTrigger>
                <DialogSurface className={dialogClasses.surface}>
                    <DialogBody>
                        <DialogTitle className={classes.title}>Select range to filter</DialogTitle>
                        <DialogContent className={dialogClasses.content}>
                            <DateRangePicker
                                ranges={[
                                    {
                                        startDate: startDate,
                                        endDate: endDate,
                                        key: 'selection',
                                    },
                                ]}
                                onChange={handleSelect}
                            />
                        </DialogContent>
                        <DialogActions position="end" className={classes.action}>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary" onClick={handleCloseDialog}>
                                    Close
                                </Button>
                            </DialogTrigger>
                            <Button className={classes.filterButton} onClick={handleFilter}>
                                Filter
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </div>
    );
};
